import React from "react";
import Layout from "@/components/layout";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import {Col, Nav, Row, Tab} from 'react-bootstrap';


import signstand1 from "../assets/img/quadstand.jpeg"
import model1 from "../assets/img/model-1.jpeg";
import model100 from "../assets/img/model100.jpeg"
import model101 from "../assets/img/model-101.jpeg";
import model103 from "../assets/img/model103.jpeg";
import model108 from "../assets/img/model108.jpeg";
import model110 from "../assets/img/model110.jpeg";
import modelhl100 from "../assets/img/modelhl100.jpeg";
import modelrp from "../assets/img/modelrp.jpeg";
import modeljd from "../assets/img/modeljd.jpeg";
import prud from "../assets/img/model-Prudential.jpeg";
import banker from "../assets/img/model-Banker.jpeg";
import rider from "../assets/img/model-Name-Rider.jpeg";
import step from "../assets/img/model-Step-Stakes.jpeg";



const SignStands = () => {
    return (
        <MenuContextProvider>
            <Layout PageTitle="Sign Stands">
                <HeaderOne/>
                <PageHeader title="Sign Stands" name="Sign Stands"/>
                <div className="sec-title text-center">
                    <p class={"m-5 p-5 "}>
                        From sign stands to sign hangers, we have a wide variety of options that offer a range in
                        durability, transportability, stylishness, and more.<br/> You will find what you need here.
                    </p>
                </div>

                        <div className="tab-wrapper m-3">
                            <div className='container-fluid'>
                                <div className="row">
                                    <div className="col-sm-12">

                                        <Tab.Container defaultActiveKey="quad">
                                            <Row className="row justify-content-center">
                                                <Col sm={3}>
                                                    <Nav variant="pills" className="flex-column">
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="quad">Quad Sign Stands</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="1">Model 1</Nav.Link>
                                                        </Nav.Item>

                                                        <Nav.Item>
                                                            <Nav.Link eventKey="100">Model 100</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="101">Model 101</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="103">Model 103</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="108">Model 108</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="110">Model 110</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="hl100">Model HL-100</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="rp100">Model RP-100</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="jd">Model JD</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="prudential">Model Prudential</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="banker">Model Banker</Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="writer">Model Name Rider </Nav.Link>
                                                        </Nav.Item>
                                                        <Nav.Item>
                                                            <Nav.Link eventKey="stakes"> Model Step Stakes</Nav.Link>
                                                        </Nav.Item>


                                                    </Nav>

                                                </Col>
                                                <Col sm={9} md ={8} lg={7}>
                                                    <Tab.Content defaultActiveKey="quad">
                                                        <Tab.Pane eventKey="quad">
                                                            <h5>Quad Sign Stands </h5>
                                                            <p>
                                                                Our Quad Sign Stands are used primarily for construction, safety, and traffic management. These stands are perfect for temporary roadside sign mounting.

                                                                <br/><br/>These stands are 24 x 48”. They come with two flag holders and a mounting bracket that fits both our pre-drilled 60x60cm and 75x75cm traffic signs.

                                                                <br/><br/>If you’d like to order Quad Sign Stands, please submit a quote request today or, if you have any questions, please feel free to reach out to us directly.
                                                            </p>
                                                            <img src={signstand1} alt=""/>


                                                        </Tab.Pane>   <Tab.Pane eventKey="1">
                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5>Model 1</h5>
                                                                <p>
                                                                    Our Model 1 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These frames are especially durable and sturdy thanks to their one-piece construction.
                                                                    <br/><br/>
                                                                    Our Model 1 frames are top-loading and packaged with 2 frames. The overall height is 48”.
                                                                    <br/><br/>
                                                                    The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={model1} alt=""/>
                                                            </Col>
                                                        </Row>
                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td align="center"> 1-241848<br/></td>
                                                                <td align="center"> 24w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">1-241848</td>
                                                                <td align="center"> 24w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center"> 1-243248<br/></td>
                                                                <td align="center"> 24w x 32h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">1-243648</td>
                                                                <td align="center"> 24w x 36h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center"> 1-301848<br/></td>
                                                                <td align="center"> 230w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">1-322448</td>
                                                                <td align="center"> 32w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">1-241848</td>
                                                                <td align="center"> *24w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">* With Ryder</td>
                                                            </tr>
                                                        </table>

                                                    </Tab.Pane>   <Tab.Pane eventKey="100">
                                                       <Row>
                                                           <Col lg={10}>
                                                               <h5>Model 100</h5>
                                                               <p>
                                                                   Our Model 100 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These frames are especially durable and sturdy thanks to their one-piece construction.

                                                                   Our Model 100 frames are top-loading and packaged with 2 frames. The overall height is 54”.
                                                                   <br/><br/>
                                                                   The various specifications are as follows:  </p>
                                                           </Col>
                                                           <Col lg={2}>
                                                               <img src={model100} alt=""/>
                                                           </Col>
                                                       </Row>
                                                        <table width="60%" border="1" cellPadding="1" cellSpacing="1">
                                                            <col width="64" span="2"/>
                                                            <tr>
                                                                <th width="64">Model Number</th>
                                                                <th width="64">Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td width="50%" align="center">100-241854</td>
                                                                <td align="center">24w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">100-242454</td>
                                                                <td align="center">24w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">100-243254</td>
                                                                <td align="center">24w x 32h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">100-301854</td>
                                                                <td align="center">30w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">100-322454</td>
                                                                <td align="center">32w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">100-322452*</td>
                                                                <td align="center">32w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">100-301854*</td>
                                                                <td align="center">30w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">* With Ryder</td>
                                                                <td align="center">&nbsp;</td>
                                                            </tr>
                                                        </table>


                                                    </Tab.Pane>   <Tab.Pane eventKey="101">
                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5> Model 101 </h5>
                                                                <p>
                                                                    This frame is chic, unique, and far less industrial than other options. Our Model 101 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These frames are especially durable and sturdy thanks to their one-piece construction. With these frames, you are empowered with stability for long-term advertising.

                                                                    Our Model 101 frames are top-loading and packaged with 2 frames. The overall height is 54”.
                                                                    <br/><br/>
                                                                    The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={model101} alt=""/>
                                                            </Col>
                                                        </Row>


                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td>101-241854</td>
                                                                <td>24w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>101-242454</td>
                                                                <td>24w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>101-301854</td>
                                                                <td>30w x 18h</td>
                                                            </tr>
                                                        </table>


                                                    </Tab.Pane>   <Tab.Pane eventKey="103">
                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5>Model 103 </h5>
                                                                <p>
                                                                    This frame is chic, unique, and far less industrial than other options. Our Model 101 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These frames are especially durable and sturdy thanks to their one-piece construction. With these frames, you are empowered with stability for long-term advertising.

                                                                    Our Model 101 frames are top-loading and packaged with 2 frames. The overall height is 54”.
                                                                    <br/><br/>
                                                                    The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={model103} alt=""/>
                                                            </Col>
                                                        </Row>

                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td>103-18-1</td>
                                                                <td>18 w 1 Piece</td>
                                                            </tr>
                                                            <tr>
                                                                <td>103-24-1</td>
                                                                <td>24 w 1 Piece</td>
                                                            </tr>
                                                            <tr>
                                                                <td>103-24-2</td>
                                                                <td>24 w 2 Piece</td>
                                                            </tr>
                                                            <tr>
                                                                <td>103-30-1</td>
                                                                <td>30 w 1 Piece</td>
                                                            </tr>
                                                            <tr>
                                                                <td>103-30-2</td>
                                                                <td>30 w 2 Piece</td>
                                                            </tr>
                                                            <tr>
                                                                <td>103-33-2</td>
                                                                <td>33 w 2 Piece</td>
                                                            </tr>
                                                            <tr>
                                                                <td>103-36-2</td>
                                                                <td>36 w 2 Piece</td>
                                                            </tr>
                                                        </table>


                                                    </Tab.Pane>   <Tab.Pane eventKey="108">
                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5>Model 108</h5>
                                                                <p>
                                                                    Our Model 108 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These frames are especially durable and sturdy thanks to their one-piece construction, perfect for displaying both indoor and outdoor signage. While the frame is incredibly strong, the collapsible structure makes it easily transportable.

                                                                    Model 108 collapsible sign frames are packaged with two frames.
                                                                    <br/><br/>
                                                                    The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={model108} alt=""/>
                                                            </Col>
                                                        </Row>

                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td>08-241824</td>
                                                                <td>24w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-241830</td>
                                                                <td>24w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-242430</td>
                                                                <td>24w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-243242</td>
                                                                <td>24w x 32h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-243642</td>
                                                                <td>24w x 36h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-244854S</td>
                                                                <td>24w x 48h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-301830</td>
                                                                <td>30w x 18h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-322430</td>
                                                                <td>32w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>108-324854S</td>
                                                                <td>32w x 48h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>S (Side Loading)</td>
                                                                <td>&nbsp;</td>
                                                            </tr>
                                                        </table>
                                                    </Tab.Pane>   <Tab.Pane eventKey="110">
                                                        <Row>
                                                            <Col lg={10}>

                                                                <h5>Model 110</h5>
                                                                <p>
                                                                    Our Model 110 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These two-piece frames are easy to transport and instantly set up.

                                                                    Our Model 110 frames are top-loading and packaged with 2 frames. The overall height is 62”.

                                                                    <br/><br/> The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={model110} alt=""/>
                                                            </Col>
                                                        </Row>



                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td>110-322462</td>
                                                                <td>32w x 24h</td>
                                                            </tr>
                                                            <tr>
                                                                <td>110-362462</td>
                                                                <td>36w x 24h</td>
                                                            </tr>
                                                        </table>


                                                    </Tab.Pane>   <Tab.Pane eventKey="hl100">
                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5>Model Hl 100</h5>
                                                                <p>
                                                                    Our Model HL-100 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These two-piece frames are easy to transport and instantly set up.

                                                                    Our Model HL-100 frames are top-loading and packaged with 5 frames. The overall height is 54”.

                                                                    <br/><br/> The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={modelhl100} alt=""/>
                                                            </Col>
                                                        </Row>


                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td>HL-100</td>
                                                                <td>32w x 24h</td>
                                                            </tr>
                                                        </table>


                                                    </Tab.Pane>   <Tab.Pane eventKey="rp100">

                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5>Model RP-100</h5>
                                                                <p>
                                                                    Our Model RP-100 sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These two-piece frames are easy to transport and instantly set up.

                                                                    Our Model RP-100 frames are top-loading and packaged with 5 frames. The overall height is 54”.
                                                                    <br/><br/> The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={modelrp} alt=""/>
                                                            </Col>
                                                        </Row>
                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Insert Size (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">RP-100</td>
                                                                <td align="center">32w x 24h</td>
                                                            </tr>
                                                        </table>





                                                    </Tab.Pane>   <Tab.Pane eventKey="jd">
                                                        <Row>
                                                            <Col lg={10}>
                                                                <h5>Model JD </h5>
                                                                <p>
                                                                    Our Model JD sign hangers are very sleek. They add a stylish flare to your signage without compromising strength. Our Model JD sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated black. These frames are especially durable and sturdy thanks to their one-piece construction. With these frames, you are empowered with stability for long-term advertising.

                                                                    Model JD sign hangers are packaged with two frames and the overall height is 60”.
                                                                    <br/><br/> The various specifications are as follows: </p>
                                                            </Col>
                                                            <Col lg={2}>
                                                                <img src={modeljd} alt=""/>
                                                            </Col>
                                                        </Row>
                                                        <table width="60%" border="1" align="left" cellPadding="1"
                                                               cellSpacing="1">
                                                            <tr>
                                                                <th>Model Number</th>
                                                                <th>Arm Length (inch)</th>
                                                            </tr>
                                                            <tr>
                                                                <td align="center"> JD25<br/></td>
                                                                <td align="center"> 25w</td>
                                                            </tr>
                                                            <tr>
                                                                <td align="center">JD33</td>
                                                                <td align="center"> 33w</td>
                                                            </tr>
                                                        </table>




                                                    </Tab.Pane>

                                                        <Tab.Pane eventKey="prudential">
                                                            <Row>
                                                                <Col lg={10}>
                                                                    <h5>Model Prudential</h5>
                                                                    <p>
                                                                        Our Model Prudential sign hangers are simple and chic. They add a stylish flare to your signage without compromising strength. These sign stands are strong and durable, made with steel. They are machine cut, welded, and powder coated blue. These frames are especially durable and sturdy thanks to their one-piece construction. With these frames, you are empowered with stability for long-term advertising.

                                                                        Model Prudential sign hangers are packaged with two frames and the overall height is 60”.
                                                                        <br/><br/> The various specifications are as follows: </p>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <img src={prud} alt=""/>
                                                                </Col>
                                                            </Row>
                                                            <table width="60%" border="1" align="left" cellPadding="1"
                                                                   cellSpacing="1">
                                                                <tr>
                                                                    <th>Model Number</th>
                                                                    <th>Arm Length (inch)</th>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center">25” Arm<br/></td>
                                                                    <td align="center"> 25w</td>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center">33” Arm</td>
                                                                    <td align="center">33w</td>
                                                                </tr>
                                                            </table>
                                                        </Tab.Pane>

                                                        <Tab.Pane eventKey="banker">
                                                            <Row>
                                                                <Col lg={10}>
                                                                    <h5>Banker</h5>
                                                                    <p>Our Model Banker frames have durable steel construction. They are machine cut and welded then powder coated white. The two piece construction allows for simple setup and handling.

                                                                        This frame is 56” high and packaged with 2 frames.<br/><br/>
                                                                        The various specifications are as follows:</p>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <img src={banker} alt=""/>
                                                                </Col>
                                                                <table width="60%" border="1" align="left"
                                                                       cellPadding="1" cellSpacing="1">
                                                                    <tr>
                                                                        <th>Model Number</th>
                                                                        <th>Arm Length (inch)</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center"> CB-103 <br/></td>
                                                                        <td align="center"> 24</td>
                                                                    </tr>
                                                                </table>
                                                            </Row>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="writer">
                                                            <Row>
                                                                <Col lg={10}>
                                                                    <h5>Model Name Rider </h5>
                                                                    <p>Our Model Name Rider has durable steel
                                                                        construction. It is machine cut and welded then
                                                                        powder coated black.

                                                                        The model is specially designed to put extra signs on top of any 24” wide Model 1, Model 100, Model 101, Model 110.

                                                                        This frame is top loading and packaged with 2 frames. Overall height is 14”.<br/><br/>
                                                                        The various specifications are as follows:</p>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <img src={rider} alt=""/>
                                                                </Col>
                                                                <table width="60%" border="1" align="left"
                                                                       cellPadding="1" cellSpacing="1">
                                                                    <tr>
                                                                        <th>Model Number</th>
                                                                        <th>Insert Size (inch)</th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center"> NR-24x8x14</td>
                                                                        <td align="center">24w x 8h</td>
                                                                    </tr>
                                                                </table>
                                                            </Row>
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="stakes">
                                                            <Row>
                                                                <Col lg={10}>
                                                                    <h5>Model Step Stakes</h5>
                                                                    <p>Our Model Step Stakes are recommended for corrugated signs up to 24”x18”.
                                                                        This is a very cost effective and visually-appealing way to display your Real Estate and election signs. It is packaged with 50 stakes.<br/><br/>

                                                                        These can be laminated in your choice of 3M reflective
                                                                        vinyl. If you’re unsure, the following sizes are common
                                                                        to traffic related signage:</p>
                                                                </Col>
                                                                <Col lg={2}>
                                                                    <img src={step} alt=""/>
                                                                </Col>
                                                            </Row>
                                                            <table width="60%" border="1" align="left" cellPadding="1"
                                                                   cellSpacing="1">
                                                                <tr>
                                                                    <th>Model Number</th>
                                                                    <th>Insert Size (inch)</th>
                                                                </tr>
                                                                <tr>
                                                                    <td align="center">SS1</td>
                                                                    <td align="center">24w x 18h</td>
                                                                </tr>
                                                            </table>
                                                        </Tab.Pane>

                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </div>
                                </div>
                            </div>
                        </div>
                <Footer/>
            </Layout>
        </MenuContextProvider>
    );
};

export default SignStands;
